<template>
  <div class="mx-8">
    <v-subheader class="py-0 d-flex justify-space-between">
      <h3>DATA RKAKL {{ tahun }}</h3>

      <v-dialog v-model="dialogAdd" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="success" v-bind="attrs" v-on="on"> Tambah Data </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 success white--text">
            Tambah Data
          </v-card-title>

          <br />

          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                label="url spreadsheet"
                v-model="dataAdd.url"
                :rules="requiredRules"
                required
              ></v-text-field>
              <v-textarea
                label="Keterangan"
                v-model="dataAdd.keterangan"
                :rules="requiredRules"
                required
              ></v-textarea>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogAdd = false">
              Batal
            </v-btn>
            <v-btn color="primary" text @click="addDataRKAKL()"> Simpan </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-subheader>

    <div class="mb-4"></div>

    <v-data-table :headers="headers" :items="listURLRKAKL" disable-sort>
      <template v-slot:item.nomor="{ item }">
        <span>{{ listURLRKAKL.indexOf(item) + 1 }}</span>
      </template>

      <template v-slot:item.url="{ item }">
        <span style="word-break: break-all"
          ><a :href="item.url" target="_blank">{{ item.url }}</a>
        </span>
      </template>

      <template v-slot:no-data>
        <label>Tidak ada data</label>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              class="mr-2"
              @click="showDialogEdit(item)"
              >mdi-pencil-box</v-icon
            >
          </template>
          <span>Edit</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              @click="showDialogDelete(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Hapus</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogEdit" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Edit Data
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="url spreadsheet"
              v-model="dataEdit.url"
            ></v-text-field>
            <v-textarea
              label="Keterangan"
              v-model="dataEdit.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="editDataRKAKL()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Data
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus data url
          <strong> {{ dataDelete.url }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteDataRKAKL()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tahun: this.$route.params.tahun,
      id: this.$route.params.id,
      headers: [
        { text: "No.", value: "nomor", width: "50px" },
        { text: "Link", value: "url" },
        { text: "Keterangan", value: "keterangan" },
        { text: "Aksi", value: "actions" },
      ],
      dialogAdd: false,
      dataAdd: {
        url: "",
        keterangan: "",
      },
      dialogEdit: false,
      dataEdit: {
        id: "",
        url: "",
        keterangan: "",
      },
      dialogDelete: false,
      dataDelete: {
        id: "",
        url: "",
      },

      valid: true,
      requiredRules: [(v) => !!v || "Data harus diisi"],
      snackbar: false,
      snackbarText: "",
    };
  },
  watch: {
    dialogAdd() {
      if (!this.dialogAdd) {
        this.$refs.form.reset();
        this.dataAdd.url = "";
        this.dataAdd.keterangan = "";
      }
    },
  },
  computed: {
    listURLRKAKL() {
      return this.$store.getters.listURLRKAKL;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch("getListURLRKAKL", this.id);
    },
    addDataRKAKL() {
      this.$refs.form.validate();
      if (this.dataAdd.url != "" && this.dataAdd.keterangan != "") {
        if (this.valid) {
          const data = {
            rkbmn: this.id,
            url: this.dataAdd.url,
            keterangan: this.dataAdd.keterangan,
          };
          this.$store.dispatch("addRKAKL", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogAdd = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "url dan keterangan tidak boleh kosong";
      }
    },

    showDialogEdit(item) {
      this.dataEdit.id = item._id;
      this.dataEdit.url = item.url;
      this.dataEdit.keterangan = item.keterangan;
      this.dialogEdit = true;
    },

    editDataRKAKL() {
      const data = {
        id: this.dataEdit.id,
        url: this.dataEdit.url,
        keterangan: this.dataEdit.keterangan,
      };

      this.$store.dispatch("editRKAKL", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEdit = false;
        }
      });
    },

    showDialogDelete(item) {
      this.dataDelete.id = item._id;
      this.dataDelete.url = item.url;
      this.dialogDelete = true;
    },

    deleteDataRKAKL() {
      const data = {
        id: this.dataDelete.id,
      };
      this.$store.dispatch("deleteRKAKL", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        this.dialogDelete = false;
        if (value.hasil == "success") {
          this.init();
        }
      });
    },
  },
};
</script>